import React, { useRef } from "react";
import Nav from "./nav";
import Body from "./body";

const Landing = ({ metrics }) => {
    const supportRef = useRef(null);
    const impactRef = useRef(null);

    return (
        <>
            <Nav impactRef={impactRef} supportRef={supportRef}/>
            <Body metrics={metrics} impactRef={impactRef} supportRef={supportRef}/>
        </>
    );
}

export default Landing;