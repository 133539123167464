import React from "react";

const MarqueeItem = ({ src }) => {
    return (
        <>
            <div className="marquee-item-wrapper">
                <img src={src} alt={"Institution"}/>
            </div>
        </>
    );
}

export default MarqueeItem;