import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Lock from "./lock";
import Key from "./key";
import Enter from "./enter";
import Password from "./password";

const ResetPasswordWithToken = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Update password");
    const [form, setForm] = useState({
        password: "",
        confirmPassword: ""
    })

    function updateConfirmPassword(confirmPassword) {
        setForm(prevForm => ({
            ...prevForm,
            confirmPassword: confirmPassword
        }))
        setText("Update password")
    }

    function updatePassword(password) {
        setForm(prevForm => ({
            ...prevForm,
            password: password
        }))
        setText("Update password")
    }

    const fetchResetPasswordProcess = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        for (const value in form) {
            if (form[value] === "") {
                setText("Please input a new password")
                return;
            }
        }

        if (form.password.length > 64) {
            setText("Password must have 63 or fewer characters");
            return;
        }

        if (((form.password !== form.confirmPassword) || form.password.length < 12) && form.password !== "") {
            if (form.password.length < 12) {
                setText("Password is not at least 12 characters")
            } else {
                setText("Passwords don't match")
            }

            return;
        }

        const url = process.env.REACT_APP_USER_API_URL + "/verify-reset-password";

        const urlOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                newPassword: form.password,
                token: token
            })
        }

        try {
            setRequestLifeCycle(true)
            const response = await fetch(url, urlOptions); 
            const data = await response.json();           
            if (response.status === 200) {
                setText("Success");
                navigate("/login")
            } else if (response.status === 400) {
                setText("Reset password link is expired or invalid")
            } else if (response.status === 415) {
                setText("Password contains invalid characters");
            } else if (response.status === 404) {
                setText("User not found")
            } else if (response.status === 405) {
                setText(data.detail);
            } else {
                setText("Status Code: " + response.status + " Error: " + data.detail)
            }        
            
        } catch (error) {
            setText("Error: " + error.message);
            console.log(error)
        } finally {
            setRequestLifeCycle(false);
        }
    }


    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                Reset Password In Progress
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    All Fields Required
                                </div>   
                                <div className="banner top">
                                    Enter your new password below
                                </div>
                                <Password top={"Password (12+ Characters)"} require={true} password={true} value={form.password} updateValue={updatePassword} placeholder={""} autoComplete={"new-password"} type={"text"}/>
                                <Password top={"Confirm Password"} require={true} password={true} value={form.confirmPassword} updateValue={updateConfirmPassword} placeholder={""} autoComplete={"new-password"} type={"text"}/>
                                <div className="gray-svg-container">
                                    <Lock checked={true}/>
                                    <Key checked={true}/>
                                </div>
                                <Enter requestLifeCycle={requestLifeCycle} submitForm={fetchResetPasswordProcess} text={text}/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPasswordWithToken;