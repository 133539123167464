import React from "react";
import Card from "./card";
import Paper from "./paper";
import Tablet from "./tablet";
import Pencil from "./pencil";
import Stylus from "./stylus";
import Workspace from "./workspace";
import Stack from "./stack";
import FeatureRow from "./featureRow";
import SmallCard from "./smallCard";
import Checkmark from "./checkmark";
import Grade from "./grade";
import Institution from "./institution";
import HowWorksRow from "./howWorksRow";
import Hyrbid from "./hybrid";
import Globe from "./globe";
import useWindowWidth from "./useWindowWidth";
import OnlyLogo from "./onlyLogo";
import BottomHeader from "./bottomHeader";
import Pdf from "./pdf";
import Stopwatch from "./stopwatch";
import Speed from "./speed";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import MarqueeItem from "./marqueeItem";
import StatsCard from "./statsCard";

const Body = (props) => {
    const navigate = useNavigate();
    const width = useWindowWidth(1250);
    const widthTwo = useWindowWidth(1130);
    const widthThree = useWindowWidth(500);
    const widthSix = useWindowWidth(700);
    const widthSeven = useWindowWidth(615);
    const widthEight = useWindowWidth(380);
    const widthNine = useWindowWidth(340);
    const featureSet = [
        {
            feature: "Web Platform",
            free: true,
            soon: false,
        },        
        {
            feature: "Configurable Code Editor",
            free: true,
            soon: false,
        },
        {
            feature: "Real-Time Debugging",
            free: true,
            soon: false,
        },
        {
            feature: "Unit Test Autograding",
            free: true,
            soon: false,
        },
        {
            feature: "Docker Environments",
            free: true,
            soon: false,
        },
        {
            feature: "Unit Test Builder GUI",
            free: true,
            soon: false,
        },        
        {
            feature: "AI-Assisted Grading",
            free: true,
            soon: false,
        },
        {
            feature: "Traditional Autograding",
            free: true,
            soon: false,
        },
        {
            feature: "Entry Codes",
            free: true,
            soon: false,
        },
        {
            feature: "Feedback Comments",
            free: true,
            soon: false,
        },
        {
            feature: "Evaluation Statistics",
            free: true,
            soon: false,
        },
        {
            feature: "Submissions Export",
            free: true,
            soon: false,
        },        
        {
            feature: "Data Export",
            free: true,
            soon: false,
        },
        {
            feature: "Multiple Forms",
            free: true,
            soon: false,
        },
        {
            feature: "Multiple Instructors",
            free: true,
            soon: false,
        },
        {
            feature: "Dynamic Grade Publishing",
            free: true,
            soon: false,
        },        
        {
            feature: "Real-Time Grading",
            free: true,
            soon: false,
        },
        {
            feature: "LMS Integration",
            free: false,
            soon: true,
        },
        {
            feature: "Single Sign On (SSO)",
            free: false,
            soon: true,
        },
        {
            feature: "Poll Mode",
            free: false,
            soon: true,
        },
        {
            feature: "Group Evaluations",
            free: false,
            soon: true,
        }
    ]

    const autograderRuns = (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 16 16">
            <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
        </svg>
    )

    const answers = (
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"/>
            <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"/>
        </svg>
    )

    const submissions = (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 16 16">
            <path d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z"/>
            <path d="M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925"/>
        </svg>
    )

    const users = (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
        </svg>
    )

    const metrics = props?.metrics;
    const showMetrics = metrics?.answers !== -1 && metrics?.submissions !== -1 && metrics?.users !== -1 && metrics?.autograderRuns !== -1;

    return (
        <>
            <div className="body-content">
                <div className="blue-back">
                    <div className="center-text" style={{ rowGap: widthSeven ? "14px" : "" }}>
                        <div className="title" style={{ fontSize: widthEight ? "20px" : "" }}>
                            Effortless, Instant Evaluation
                            {
                                widthSix 

                                ?

                                    <>
                                    </>

                                :

                                    <>
                                        ... with <span className="govaluate" onClick={() => {
                                                        navigate("/sign-up")
                                                    }}> GoValuate </span>
                                    </>
                            }
                        </div>
                        <div className="subtitle" style={{ fontSize: widthNine ? "14px" : "" }}>
                            { widthSix ? "B" : "Simply b" }uild your evaluation, and we take care of the rest
                        </div>
                        <div className="try-it" style={{ marginTop: widthSeven ? "14px" : "" }} onClick={() => {
                            navigate("/sign-up")
                        }}>
                            Try GoValuate for Free
                        </div>
                    </div>
                    <div className="center-for-cards">
                        <div className="card-container-absolute">                            
                            <Card left={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                        </svg>} 
                                    right={<Stylus />} button={"Instant Autograding"} title={"Docker-Based Unit Test Autograding with File-To-Question Mapping"}/>
                            <Card left={<Pencil />} right={<Pdf />} button={"Configurable Editor"} title={"Configurable Code Editor Question Type (70+ Languages & Plaintext)"}/>
                            <Card left={<Stopwatch />} right={<Speed />} button={"Real-time Debugging"} title={"Students Debug Reponses in Real-Time"}/>
                            <Card left={<Paper />} right={<Tablet />} button={"Unit Test GUI"} title={"Unit-Test-Creation Graphical User Interface"}/>                            
                            <Card left={<Stack />} right={<Workspace />} button={"AI-Assisted Grading"} title={"AI Grading for Clarity and Correctness of Short Responses"}/>
                        </div>
                    </div>
                    <div className="chart-works" style={{ marginTop: "300px" }} ref={props.impactRef}>
                        <div className="how-title" style={{ height: "70px" }}>
                            Our Impact
                        </div>
                        <Marquee className="marquee-container-parent">
                            <div className="marquee-container">
                                <MarqueeItem src={"/static/media/uncch.png"}/>
                            </div>
                        </Marquee>
                        {
                            showMetrics && (
                                <div className="stats-container" style={{ flexDirection: widthTwo ? "column" : "" }}>
                                    <StatsCard number={metrics.autograderRuns} metric={"Autograder Runs"} svg={autograderRuns}/>
                                    <StatsCard number={metrics.answers} metric={"Answers Graded"} svg={answers}/>
                                    <StatsCard number={metrics.submissions} metric={"Submissions"} svg={submissions}/>
                                    <StatsCard number={metrics.users} metric={"Users"} svg={users}/>
                                </div>
                            )
                        }
                    </div>
                    <div className="chart-works" style={{ marginTop: "300px" }}>
                        <div className="how-title" style={{ height: "70px" }}>
                            How It Works
                        </div>
                        <div className={ widthTwo ? "small-card-container-phone" : "small-card-container" }>
                            <SmallCard svg={<OnlyLogo />} text={"Build Evaluation"}/>
                            <SmallCard svg={<Checkmark />} text={"Students complete assignment/assessment"}/>
                            <SmallCard svg={<Grade />} text={"Instant autograding and data export"}/>

                            {
                                widthTwo 

                                ?

                                    <div className="bar-phone"/>

                                :

                                <>
                                    <div className="bar" style={{ top: width ? "22.5%" : "32.5%" }} />
                                </>

                            }

                            <div className="how-container" style={{ flexDirection: width ? "column" : "" }}>
                                <HowWorksRow svg={<Globe />} text={"Increase Collaboration"} />
                                <HowWorksRow svg={<Hyrbid />} text={"Go Paperless with GoValuate"} />
                                <HowWorksRow svg={<Institution />} text={"Grade and Build Faster"} />                                
                            </div>
                        </div>
                    </div>  
                    <div className="how-it-works" style={{ marginTop: "300px" }}>
                        <div className="slant-title">
                            What We Offer
                        </div>
                        <div className="solutions-header">
                            <div className="header" style={{ width: widthThree ? "50%" : "33.3%", justifyContent: "flex-start" }}>
                                Feature
                            </div>
                            {
                                widthThree

                                ?

                                <>
                                    <div className="header" style={{ width: "50%", justifyContent: "flex-end" }}>
                                        Available & Free
                                    </div>
                                </>

                                :

                                <>
                                    <div className="header" style={{ width: "33.3%", justifyContent: "center" }}>
                                        Available & Free
                                    </div>
                                    <div className="header" style={{ width: "33.3%", justifyContent: "flex-end" }}>
                                        Coming Soon
                                    </div>
                                </>

                            }
                        </div>
                        {
                            featureSet.map((feature, index) => {
                                return <FeatureRow key={feature.feature} width={widthThree} index={index} feature={feature.feature} free={feature.free} soon={feature.soon}/>
                            })
                        }
                    </div>
                    <BottomHeader supportRef={props.supportRef}/>
                </div>
            </div>
        </>
    );
}

export default Body;