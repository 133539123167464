import React from "react";
import LogoFill from "./logoFill";
import RightNav from "./rightNav";
import useWindowWidth from "./useWindowWidth";
import { useNavigate } from "react-router-dom";

const Nav = (props) => {
    const navigate = useNavigate();
    const width = useWindowWidth(700);

    return (
        <>
            <div className="nav">
                <LogoFill />
                {
                    width

                    ?

                        <div className="login" onClick={() => {
                            navigate("/login")
                        }}>
                            Log In
                        </div>

                    :

                        <>
                            <RightNav impactRef={props.impactRef} supportRef={props.supportRef}/>
                        </>

                }
            </div>
        </>
    );
}

export default Nav;