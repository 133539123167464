import { useEffect, useState } from "react";
import SignupForm from "./components/signupForm";
import Login from "./components/login";
import VerifyEmail from "./components/verifyEmail";
import ConfirmEmail from "./components/confirmEmail";
import ConfirmEmailWithToken from "./components/confirmEmailWithToken";
import EnterReset from "./components/enterReset";
import ResetPassword from "./components/resetPassword";
import ResetPasswordWithToken from "./components/resetPasswordWithToken";
import Landing from "./components/landing";
import TermsAndConditions from "./components/termAndConditions";
import PrivacyPolicy from "./components/privacyPolicy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
    const [metrics, setMetrics] = useState({
        answers: -1,
        submissions: -1,
        users: -1,
    })

    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_USER_API_URL + "/get-role", {
                    method: "GET",
                    credentials: "include"
                })
                const data = await response.json()
                if (data.role === "professor") {
                    window.location.href = process.env.REACT_APP_INSTRUCTOR_REDIRECT_URL;
                }
                else if (data.role === "student") {
                    window.location.href = process.env.REACT_APP_STUDENT_REDIRECT_URL;
                }

            } catch (error) {
                console.log(error)
            }
        }

        fetchRole();
    }, [])

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_USER_API_URL + "/get-table-metrics", {
                    method: "GET",
                    credentials: "include"
                })
                const data = await response.json();

                if (response.status === 200) {
                    setMetrics(data);
                }

            } catch (error) {
                console.log(error);
            }
        }

        fetchMetrics();
    }, [])

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Landing metrics={metrics}/>}/>
                    <Route path="/sign-up" element={<SignupForm />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/verify-email" element={<VerifyEmail />}/>
                    <Route path="/confirm-email/:email/" element={<ConfirmEmail />}/>
                    <Route path="/confirm-email/:email/:token" element={<ConfirmEmailWithToken />}/>
                    <Route path="/reset-password" element={<EnterReset />}/>
                    <Route path="/reset-password/:email/" element={<ResetPassword />}/>
                    <Route path="/reset-password/:email/:token" element={<ResetPasswordWithToken />}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                </Routes>
            </Router>
        </>
    );
}

export default App;
