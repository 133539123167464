import React from "react";
import { useNavigate } from "react-router-dom";

const RightNav = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="right-nav">
                <div className="nav-text" onClick={() => {
                    props.impactRef.current.scrollIntoView({ behavior: 'smooth' }); 
                }}>
                    Impact
                </div>
                <div className="nav-text" onClick={() => {
                    props.supportRef.current.scrollIntoView({ behavior: 'smooth' }); 
                }}>
                    Contact
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", columnGap: "10px" }}>
                    <div className="signup" onClick={() => {
                        navigate("/sign-up")
                    }}>
                        Sign Up
                    </div>
                    <div className="login" onClick={() => {
                        navigate("/login")
                    }}>
                        Log In
                    </div>
                </div>
            </div>
        </>
    );
}

export default RightNav;