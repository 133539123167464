import React from "react";

const StatsCard = ({ number, metric, svg }) => {
    const roundedNumber = number >= 0 ? (number > 10 ? Math.floor(number / 10) * 10 : number) : 0;
    
    return (
        <>
            <div className="stats-card">
                <div className="small-card overwrite-small-card">
                    {roundedNumber.toLocaleString()}+
                    <div className="svg-stats-card">
                        {svg}
                    </div>                    
                </div>
                <div className="bottom-of-stats-card">
                    {metric}
                </div>
            </div>
        </>
    );
}

export default StatsCard;