import React from "react";
import Loading from "./loading";

const Enter = (props) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (e.g., form submission)
            props.submitForm();    // Calls the submitForm function
        }
    };

    return (
        <>
            {
                props.requestLifeCycle
                
                ?

                    <div className="banner" style={{ cursor: "pointer" }}>
                        <>
                            <Loading />
                        </>
                    </div>

                :

                <button className="banner" onClick={props.submitForm} onKeyDown={handleKeyDown}>
                    {props.text}
                </button>

            }
        </>
    );
}

export default Enter;